<template>
  <div class="search-bar-component" :class="{ mobile: !$full }">
    <div class="search-bar" :class="{ hasResults: hotels.length > 0 }">
      <div class="icon" @click="openSidebar()">
        <md-icon>{{ $full ? 'search' : 'menu' }}</md-icon>
      </div>
      <input
        :placeholder="$t('search.searchHotel')"
        @input="e => (query = e.target.value)"
        :value="query"
      />
    </div>

    <div class="results">
      <div
        class="result"
        v-for="hotel in hotels"
        :key="hotel.id"
        @click="openHotel(hotel)"
      >
        <p>{{ hotel.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: '',
      hotels: [],
      updateTimeout: null,
    }
  },
  watch: {
    query() {
      if (this.updateTimeout) {
        clearTimeout(this.updateTimeout)
        this.updateTimeout = null
      }
      this.updateTimeout = setTimeout(this.updateResults, 200)
    },
  },
  methods: {
    openSidebar() {
      if (this.$full) return
      this.$store.sidebarOpened = !this.$store.sidebarOpened
    },
    openHotel(hotel) {
      this.$router.push('/hotel/' + hotel.id)
      this.query = ''
    },
    updateResults() {
      this.query = this.query.trim()
      if (this.query.length <= 0) {
        this.hotels = []
        return
      }

      this.$api
        .get(`hotels/search?title=${encodeURI(this.query)}`)
        .then(res => {
          this.hotels = res.data.data
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.search-bar-component {
  transition: all 0.2s;
  position: absolute;
  top: 30px;
  left: 10px;

  &.mobile {
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .search-bar {
    width: 300px;
    height: 48px;
    display: flex;
    background-color: white;
    box-shadow: 0 1px 11px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px;

    &.hasResults {
      border-radius: 8px 8px 0 0;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.75;
      margin-left: 15px;
      margin-right: 10px;
    }

    input {
      flex: 1;
      border: 0;
      outline: 0;
      margin-right: 5px;

      &::placeholder {
        color: #8492a6;
      }
    }
  }

  .results {
    background-color: white;
    max-height: 300px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .result {
      position: relative;
      background-color: white;
      height: 32px;
      cursor: pointer;
      transition: all 0.2s;
      color: rgb(50, 50, 50);

      &:hover {
        background-color: rgb(240, 240, 240);
      }

      p {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-left: 15px;
      }
    }
  }
}
</style>
