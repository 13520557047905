<template>
  <div id="sidebar" v-touch:swipe.left="closeSidebar" ref="sidebar">
    <div v-if="!isAuthorized" class="not-authorized" ref="content">
      <p class="title">{{ $t('sidebar.logIntoAccount') }}</p>
      <p class="subtitle">{{ $t('sidebar.notAuthLabel') }}</p>
      <div
        class="image"
        :style="{
          backgroundImage: `url(${require('@/assets/EnteringHotel.png')})`,
        }"
      ></div>
      <div class="button" @click="$events.emit('do-login-popup')">
        <span class="label">{{ $t('sidebar.logIn') }}</span>
      </div>
    </div>
    <div v-else class="authorized">
      <div class="user" :style="{ opacity: user != null ? '1' : '0' }">
        <div class="icon">
          <md-icon>person</md-icon>
        </div>
        <div class="displayName">{{ displayName }}</div>
      </div>
      <div class="seperator"></div>
      <div class="tabs">
        <div
          class="tab"
          v-for="(tab, key) in tabs"
          :key="key"
          v-show="tab.if ? tab.if() : true"
        >
          <p class="label" @click="collapse(tab)">
            {{ tab.label }}
            <md-icon>{{
              tab.collapsed ? 'expand_more' : 'expand_less'
            }}</md-icon>
          </p>
          <div class="seperator"></div>
          <div
            class="items"
            :class="{ collapsed: tab.collapsed }"
            v-if="!tab._hidden"
          >
            <div
              class="item"
              v-for="(item, key) in tab.items"
              :key="key"
              @click="click(item)"
              v-show="item.if ? item.if() : true"
            >
              <router-link
                class="label"
                :to="item.route"
                v-if="item.route && ($store.isAuthed || item.allowUanth)"
                >{{ item.label }}</router-link
              >
              <p class="label" v-else>{{ item.label }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div ref="marker"></div>
    <div
      ref="dummy"
      v-if="linksFloatBottom"
      :style="{ height: linksHeight + 'px' }"
    ></div>

    <div
      class="links"
      ref="links"
      :style="{ position: linksFloatBottom ? 'absolute' : '' }"
    >
      <a @click="showAboutPopup">{{ $t('sidebar.legal4') }}</a>
      <a href="https://partner.cncrg.org/user-agreement" target="_blank">{{
        $t('sidebar.legal2')
      }}</a>
    </div>
  </div>
</template>

<script>
import { parsePhoneNumber } from 'libphonenumber-js'

export default {
  data() {
    return {
      window,
      localStorage,
      tabs: [
        {
          if: () => !this.user?.crmRelatedUser,
          label: this.$t('sidebar.myStay'),
          items: [
            {
              label: this.$t('sidebar.myStays'),
              route: '/user/stays',
            },
            {
              label: this.$t('sidebar.myOrders'),
              route: '/user/orders',
            },
            {
              label: this.$t('sidebar.myMessages'),
              route: '/user/messages',
            },
          ],
        },
        {
          label: this.$t('sidebar.settings'),
          items: [
            {
              if: () => !this.user?.crmRelatedUser,
              label: this.$t('sidebar.language'),
              route: '/settings/language',
            },
            {
              if: () => !this.user?.crmRelatedUser,
              label: this.$t('sidebar.notifications'),
              route: '/settings/notifications',
            },
            {
              allowUnauth: true,
              label: this.$t('sidebar.logout'),
              func: () => {
                const logout = () => {
                  this.$api.get(`user/logout`).finally(() => {
                    this.$store.user = null
                    this.$store.overrideToken = null
                    this.$store.overrideCustomerId = null
                    localStorage.removeItem('token')
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('customerId')
                    delete this.$api.defaults.headers.common['x-customer-id']
                    delete this.$api.defaults.headers.common.Token
                    delete sessionStorage.roomBreakfastCategoryIdSaves
                    delete sessionStorage.breakfastOrderIdSaves
                    this.$events.emit('authorized')
                  })
                }
                this.$events.emit(
                  'show-confirm',
                  this.$t('sidebar.logoutSure'),
                  logout,
                )
              },
            },
          ],
        },
      ],
      user: null,
      isAuthorized: false,
      linksFloatBottom: false,
      linksHeight: 0,
    }
  },
  created() {
    this.updateUser()
  },
  mounted() {
    this.linksFloatBottomInterval = setInterval(() => {
      if (typeof this.$refs.links !== 'undefined') {
        this.updateLinksFloatButton()
      }
    }, 3000)
  },
  events: {
    authorized() {
      this.updateUser()
    },
  },
  methods: {
    collapse(tab) {
      tab.collapsed = !tab.collapsed
      this.$forceUpdate()

      if (tab.collapsed)
        setTimeout(() => {
          tab._hidden = tab.collapsed
          this.updateLinksFloatButton()
          this.$forceUpdate()
        }, 200)
      else {
        tab._hidden = tab.collapsed
        this.updateLinksFloatButton()
        this.$forceUpdate()
      }
    },
    click(item, navigateRoute = false) {
      if (!this.$store.isAuthed && !item.allowUnauth) {
        return this.$events.emit('do-login-popup', {
          callback: () => this.click(item, true),
        })
      }
      this.$store.sidebarOpened = !this.$store.sidebarOpened
      if (navigateRoute && item.route) this.$router.push(item.route)
      if (item.func) item.func()
    },
    updateLinksFloatButton() {
      this.$forceUpdate()
      this.$nextTick(() => {
        if (!this.$refs.marker) return
        this.linksHeight = this.$refs.links.getBoundingClientRect().height
        this.linksFloatBottom =
          this.$refs.sidebar.getBoundingClientRect().bottom >
          this.$refs.marker.getBoundingClientRect().bottom + this.linksHeight
      })
    },
    closeSidebar() {
      this.$store.sidebarOpened = !this.$store.sidebarOpened
    },
    showAboutPopup() {
      this.$events.emit(
        'show-alert',
        this.$t('sidebar.legal4Popup.title'),
        `
        <h6 style="margin:0">${this.$t('sidebar.legal4Popup.subtitle')}</h6>
        ${this.$t('sidebar.legal4Popup.description')}

        <a href="mailto:support@cncrg.org">${this.$t('sidebar.support')}</a>
        <a href="https://partner.cncrg.org" target="_blank">${this.$t(
          'sidebar.legal3',
        )}</a>
        <a href="https://partner.cncrg.org/personal-data" target="_blank">${this.$t(
          'sidebar.legal1',
        )}</a>
      `,
      )
    },
    updateUser() {
      const isAuthorized = () =>
        this.$store.isAuthed ||
        this.$api.defaults.headers.common['x-customer-id'] != null

      this.isAuthorized = isAuthorized()
      if (isAuthorized()) {
        this.$api.get(`user`).then(res => {
          if (isAuthorized()) this.user = res.data.data
        })
      } else {
        this.user = null
      }

      this.updateLinksFloatButton()
    },
    _resize() {
      if (typeof this.$refs.links === 'undefined') return
      this.updateLinksFloatButton()
    },
  },
  computed: {
    displayName() {
      if (!this.user) return null
      return this.user?.crmRelatedUser
        ? `${this.user.firstName} ${this.user.lastName}`
        : this.formattedPhoneNumber
    },
    formattedPhoneNumber() {
      if (!this.user?.phoneNumber) return null
      return parsePhoneNumber(this.user.phoneNumber).formatInternational()
    },
  },
  beforeDestroy() {
    clearInterval(this.linksFloatBottomInterval)
  },
}
</script>

<style lang="scss">
#sidebar {
  position: relative;
  height: 100%;
  font-family: Roboto;
  background-color: white;
  p {
    margin-bottom: 2rem;
  }

  .not-authorized {
    padding-top: 15px;
    padding-left: 15px;
    border-bottom: 1px solid #d3dce6;
    height: 205px;

    .title {
      font-size: 24px;
    }
    .subtitle {
      font-size: 15px;
      max-width: 120px;
      color: #47525e;
    }
    .image {
      position: absolute;
      right: 0;
      top: 50px;
      width: 156px;
      height: 156px;
      background-position: center;
      background-size: cover;
    }
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #129ce6;
      border-radius: 5px;
      max-width: 120px;
      padding: 5px;
      margin-top: -25px;
      text-align: center;
      position: relative;
      cursor: pointer;
      transition: 0.2s all;

      &:hover {
        filter: brightness(1.1);
      }

      .label {
        color: #ffffff;
        font-size: 17px;
      }
    }
  }

  .support {
    height: 80px;
    line-height: 80px;
    margin-left: 15px;
    color: #47525e;
    font-size: 18px;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    transition: 0.2s all;

    &:hover {
      filter: brightness(1.2);
      text-decoration: underline;
    }
  }
  .links {
    bottom: 0;
    margin-top: 0px;
    padding-left: 15px;

    > * {
      color: #8492a6;
      font-family: Roboto;
      font-size: 12px;
      width: 212px;
      transition: 0.2s all;
      cursor: pointer;
      margin-bottom: 5px;
      display: block;

      &:hover {
        filter: brightness(1.2);
        text-decoration: underline;
      }
    }
  }

  .seperator {
    height: 1px;
    width: 100%;
    background-color: #d3dce6;
  }

  .authorized {
    .user {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0 15px 0;
      position: relative;
      transition: 0.2s all;

      .icon {
        margin-right: 5px;
      }

      .displayName {
        color: #47525e;
        font-size: 16px;
        text-align: center;
      }
    }

    .tabs {
      .tab {
        width: 100%;
        cursor: default;

        > .label {
          height: 60px;
          line-height: 60px;
          margin-left: 15px;
          margin-bottom: 0;
          color: #47525e;
          font-size: 18px;
          vertical-align: middle;
          position: relative;
          cursor: pointer;

          .md-icon {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .items {
          width: 100%;
          background-color: #f9fafc;
          padding-top: 15px;
          padding-bottom: 10px;
          transition: 0.2s all;

          &.collapsed {
            transform: scaleY(0);
            transform-origin: top;
          }

          .item {
            transition: 0.2s all;
            cursor: pointer;

            &:hover {
              filter: brightness(1.2);
              text-decoration: underline;
            }

            .label {
              color: #8492a6;
              font-size: 16px;
              margin-left: 15px;
              margin-bottom: 1rem;
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
