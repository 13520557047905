<template><div id="images-popup"></div></template>
<script>
import 'viewerjs/dist/viewer.css'
import { api } from 'v-viewer'
export default {
  data() {
    return {
      viewer: null,
    }
  },
  beforeDestroy() {
    this.viewer?.hide(true)
    this.viewer = null
  },
  events: {
    'show-images'(...args) {
      this.showImages(...args)
    },
  },
  methods: {
    showImages(images, defaultSlide = 0) {
      if (!Array.isArray(images)) images = [images]
      const oneImage = images.length <= 1

      if (this.viewer) this.viewer.hide(true)
      this.viewer = api({
        images,
        options: {
          minZoomRatio: 0.5,
          maxZoomRatio: 2,
          hidden: () => {
            this.viewer = null
          },
          toolbar: oneImage ? {} : { prev: 1, play: 1, next: 1 },
          navbar: oneImage ? false : true,
          title: false,
        },
      })
      this.viewer.view(defaultSlide)
    },
  },
  watch: {
    viewer: {
      immediate: true,
      handler(viewer) {
        this.$store.navigationBlockers.imagePopup = viewer
          ? () => {
              this.$nextTick(() => {
                this.viewer?.hide()
                this.viewer = null
              })
            }
          : null
      },
    },
  },
}
</script>

<style lang="scss">
#image-popup {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 16;

  .popup {
    .image {
      max-width: 100%;
      max-height: 100%;
      bottom: 0;
      left: 0;
      margin: auto;
      overflow: auto;
      position: fixed;
      right: 0;
      top: 0;
      -o-object-fit: contain;
      object-fit: contain;
    }
  }
}
</style>
