<template>
  <div class="btn bell" @click="click" v-if="authorized">
    <img class="md-icon" :src="active ? IconActive : Icon" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      Icon: require('@/assets/Bell.svg'),
      IconActive: require('@/assets/BellActive.svg'),
      active: false,
    }
  },
  created() {
    this.check()
    this.checkInterval = setInterval(this.check, 15000)
  },
  beforeDestroy() {
    clearInterval(this.checkInterval)
  },
  events: {
    'check-ball'() {
      this.check()
    },
  },
  methods: {
    check() {
      if (this.authorized)
        this.$api.get(`user/messages?unreadQty=true`).then(res => {
          this.active = res.data.data > 0
        })
    },
    click() {
      this.$router.push('/user/messages')
    },
  },
  computed: {
    authorized() {
      return this.$store.isAuthed && !this.$store.overrideToken
    },
  },
}
</script>
