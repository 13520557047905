<template>
  <div class="debug-redirectors" v-if="isEnabled">
    <a
      v-for="redir in redirectors"
      :key="redir.link"
      :href="redir.link + $route.fullPath"
      :class="{ disabled: location.origin.startsWith(redir.link) }"
      >{{ redir.title }}</a
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      location,
      redirectors: [
        {
          title: 'LOCAL',
          link: 'http://localhost:8080',
        },
        {
          title: 'DEV',
          link: 'https://web-concierge.dev.cncrg.org',
        },
        {
          title: 'PROD',
          link: 'https://cncrg.org',
        },
      ],
    }
  },
  computed: {
    isEnabled() {
      return (
        process.env.VUE_APP_ENABLE_DEBUG_REDIRECTORS == 'true' ||
        sessionStorage.enableDebugRedirectors
      )
    },
  },
}
</script>

<style lang="scss">
.debug-redirectors {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 6px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 11px 1px rgba(0, 0, 0, 0.08);
  display: flex;
  gap: 5px;

  > a {
    &.disabled {
      pointer-events: none;
      cursor: default;
      color: black;
    }
  }
}
</style>
