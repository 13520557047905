<template>
  <div id="map" style="width: 100%; height: 100%;">
    <div id="map-container" />
    <SearchBar />
    <div class="zoom-buttons">
      <div class="btn" @click="map.setZoom(zoom + 1)">
        <md-icon>add</md-icon>
      </div>
      <br />
      <br />
      <div class="btn" @click="map.setZoom(zoom - 1)">
        <md-icon>remove</md-icon>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div
        class="btn"
        @click="resetCoords({ popupOnDeny: true, dontZoomCountry: true })"
      >
        <md-icon>gps_fixed</md-icon>
      </div>
      <br />
      <br />
      <Bell />
    </div>

    <DebugRedirectors />

    <Loading v-if="loading" :error="error" />
  </div>
</template>

<script>
import SearchBar from '@/components/SearchBar'
import DebugRedirectors from '@/components/DebugRedirectors'
import Bell from '@/components/Bell'
import settings from '@/settings.json'

export default {
  components: { SearchBar, Bell, DebugRedirectors },
  data() {
    return {
      map: null,
      hotels: [],
      loading: true,
      error: '',
      triedRestoreSession: false,
      clusterOptions: {
        clusterIcons: [
          {
            href: require('@/assets/HotelsCluster.png'),
            size: [40, 46],
            offset: [0, 0],
          },
        ],
        clusterIconContentLayout: null,
      },
    }
  },
  mounted() {
    this.$api
      .get(`hotels/search`)
      .then(res => {
        this.hotels = res.data.data
        this.loading = false

        this.hotels.forEach(hotel => {
          hotel.position = [hotel.latitude, hotel.longitude]
        })

        this.createMap()
      })
      .catch(err => {
        this.error = this.$t('errors.network') + '<br>' + err
      })

    setTimeout(() => {
      if (this.error == '') this.error = this.$t('errors.network')
    }, 5000)
  },
  events: {
    'set-map-position'(lat, lng) {
      this.map?.setCenter([lat, lng])
    },
  },
  methods: {
    createMap() {
      let lang = 'en_US'
      const langReplaces = {
        ru: 'ru_RU',
      }
      if (langReplaces[window.userLocale])
        lang = langReplaces[window.userLocale]

      window.ymaps = null
      const scriptElement = document.createElement('script')
      scriptElement.src = `https://api-maps.yandex.ru/2.1/?lang=${lang}&apikey=${settings.yandexApiKey}`
      scriptElement.type = 'text/javascript'
      scriptElement.onload = () => {
        window.ymaps.ready(this.initMap)
      }
      document.body.appendChild(scriptElement)
    },
    initMap() {
      if (!document.getElementById('map-container')) return
      const { ymaps } = window
      const map = (this.map = new ymaps.Map(
        'map-container',
        {
          center: [55, 80],
          zoom: 3,
          behaviors: ['default'],
          controls: [],
        },
        {
          minZoom: 3,
          autoFitToViewport: 'always',
          yandexMapDisablePoiInteractivity: true,
          suppressMapOpenBlock: true,
        },
      ))

      const clusterer = new ymaps.Clusterer(this.clusterOptions)

      const placemarks = this.hotels.map(hotel => {
        return new ymaps.Placemark(
          hotel.position,
          {},
          this.getMarkerIcon(hotel),
        )
      })

      clusterer.add(placemarks)

      clusterer.events.add('click', e => {
        const hotelId = e.get('target').options._options?.hotel.id
        if (hotelId) this.clickHotel(hotelId)
      })

      map.geoObjects.add(clusterer)

      this.$nextTick(() => {
        import('@/assets/country_coords').then(countries => {
          this.country_coords = countries
          this.canRestoreSession ? this.restoreSession() : this.resetCoords()
          this.triedRestoreSession = true
        })
      })
    },
    clickHotel(hotelId) {
      if (this.$route.params.id == hotelId) return

      const hotel = this.hotels.find(hotel => hotel.id == hotelId)
      this.map.setCenter([hotel.latitude, hotel.longitude])

      this.$nextTick(() => {
        this.$router.push(`/hotel/${hotelId}`)
      })
    },
    getMarkerIcon(hotel) {
      const { ymaps } = window
      const sizeScale = 0.8
      const iconSize = [40, 67]
      const iconOffset = [0, -iconSize[1] / 2]
      return {
        hotel,
        iconLayout: 'default#imageWithContent',
        iconImageHref: require('@/assets/HotelMarker.png'),
        iconImageSize: iconSize.map(size => size * sizeScale),
        iconImageOffset: iconSize.map(
          (size, i) => (size * sizeScale) / -2 + iconOffset[i],
        ),
        iconContent: hotel.title,
        iconContentOffset: [-35, 55],
        iconShape: {
          type: 'Rectangle',
          coordinates: [
            [-50, -50],
            [80, 50],
          ],
        },
        iconContentLayout: ymaps.templateLayoutFactory.createClass(`
          <div
            style="
            width: 100px;
            word-wrap: break-word;
            background-color: white;
            padding: 7px;
            border-radius: 20px;
            text-align: center;
            font-size: 12px;
            box-shadow: 0 1px 11px 1px rgba(0,0,0,.08);
            ">${hotel.title}</div>
        `),
      }
    },
    resetCoords(props) {
      if (props == undefined) props = {}

      let zoomToCountry = () => {
        let countries = this.country_coords
        if (countries[window.userLocale.toUpperCase()]) {
          let coords = countries[window.userLocale.toUpperCase()].coords
          this.map.setCenter(coords)
          this.map.setZoom(3)
        }
      }
      if (this.userCoords) {
        this.map.setCenter([0, 0])
        this.$nextTick(() => {
          this.map.setCenter(this.userCoords)
        })
        this.map.setZoom(15)
      } else {
        if (!props.dontZoomCountry) zoomToCountry()
        if (props.cb) props.cb()
        if (navigator.geolocation && !this.$store.isIframe) {
          navigator.geolocation.getCurrentPosition(
            a => {
              this.$api
                .get(`hotels/search/closest`, {
                  params: {
                    lat: a.coords.latitude,
                    lon: a.coords.longitude,
                  },
                })
                .then(res => {
                  let lt = res.data.data.latitude
                  let lg = res.data.data.longitude
                  this.map.setCenter([lt, lg])
                  this.userCoords = [
                    res.data.data.latitude,
                    res.data.data.longitude,
                  ]
                  this.map.setZoom(15)
                  if (props.cb) props.cb()
                })
            },
            () => {
              if (props.popupOnDeny)
                this.$events.emit(
                  'show-alert',
                  this.$t('errors.error'),
                  this.$t('map.gpsError'),
                )
            },
          )
        }
      }
    },
    restoreSession() {
      if (!this.canRestoreSession) return
      this.map.setZoom(Number(sessionStorage.map_zoom))
      this.map.setCenter([
        Number(sessionStorage.map_lat),
        Number(sessionStorage.map_lng),
      ])
    },
  },
  computed: {
    canRestoreSession() {
      return Boolean(
        sessionStorage.map_zoom &&
          sessionStorage.map_lat &&
          sessionStorage.map_lng,
      )
    },
    zoom() {
      if (!this.map) return null
      return this.map.getZoom()
    },
    position() {
      if (!this.map) return null
      return this.map.getCenter()
    },
  },
  watch: {
    zoom(zoom) {
      if (!this.triedRestoreSession) return
      sessionStorage.map_zoom = zoom
    },
    position(position) {
      if (!this.triedRestoreSession) return
      sessionStorage.map_lat = position[0]
      sessionStorage.map_lng = position[1]
    },
  },
}
</script>

<style lang="scss">
#map {
  #map-container {
    width: 100%;
    height: 100%;
  }

  .zoom-buttons {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;

    .btn {
      border-radius: 100%;
      background-color: rgba(255, 255, 255, 0.75);
      width: 48px;
      height: 48px;
      position: relative;
      pointer-events: auto;

      .md-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
